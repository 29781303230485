import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../../types/store";
import { fetchSocialContentInstagrams, fetchSocialContentInstagram } from "./social-content-actions";

interface SocialContentState {
  socialContents: Array<any>; // @todo
  socialContent: any; // @todo
  totalItems: number;
  loading: boolean;
  error: string | null;
}

const initialState: SocialContentState = {
  socialContents: [],
  socialContent: null,
  totalItems: 0,
  loading: false,
  error: null,
};

const socialContentsSlice = createSlice({
  name: "socialContents",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSocialContentInstagrams.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.socialContents = [];
        state.totalItems = 0;
      })
      .addCase(fetchSocialContentInstagrams.fulfilled, (state, action) => {
        state.loading = false;
        state.socialContents = action.payload["hydra:member"];
        state.totalItems = action.payload["hydra:totalItems"];
      })
      .addCase(fetchSocialContentInstagrams.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
        state.totalItems = 0;
      })
      .addCase(fetchSocialContentInstagram.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.socialContent = null;
      })
      .addCase(fetchSocialContentInstagram.fulfilled, (state, action) => {
        state.loading = false;
        state.socialContent = action.payload;
      })
      .addCase(fetchSocialContentInstagram.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
        state.socialContent = null;
      });
  },
});

export const {} = socialContentsSlice.actions;

export const selectSocialContentInstagrams = (state: RootState) => state.shopSocialContents.socialContents;
export const selectSocialContentInstagram = (state: RootState) => state.shopSocialContents.socialContent;
export const selectTotalItems = (state: RootState) => state.shopSocialContents.totalItems;
export const selectLoading = (state: RootState) => state.shopSocialContents.loading;
export const selectError = (state: RootState) => state.shopSocialContents.error;

export default socialContentsSlice.reducer;
