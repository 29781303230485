import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

import Logo from "../atoms/Logo";

import marketingImage from "../../assets/images/layout/marketing.png";
import { Button } from "react-bootstrap";

interface PublicLayoutProps {
  children: ReactNode;
  className?: string;
  withBackground?: boolean;
}

const PublicLayout: React.FC<PublicLayoutProps> = ({ children, className, withBackground }: PublicLayoutProps) => {
  const { t } = useTranslation();

  return (
    <div className={`vh-100 d-flex justify-content-center align-items-center ${className}`}>
      <Container fluid>
        {withBackground ? (
          <Row>
            <Col md={6} lg={4} className="position-relative">
              <div className="p-5 position-relative">
                <div className="logo mb-5">
                  <Logo withLink />
                </div>
                {children}
              </div>
              <div className="text-center">
                <Button variant="link" className="mb-3 link-dark" href={"https://tagether.fr/prpd/"} target="_blank">
                  {t("login.privacy_policy")}
                </Button>
                <Button variant="link" className="mb-3 link-dark" href={"https://tagether.fr/conditions-generales-dutilisation/"} target="_blank">
                  {t("login.terms_of_use")}
                </Button>
              </div>
            </Col>
            <Col md={6} lg={8} className="d-none d-md-block">
              <div className="p-5">
                <h2 className="text-white fw-bold">{t("login.marketing")}</h2>
                <div className="text-center mt-5">
                  <Image src={marketingImage} fluid width="50%" alt="" />
                </div>
              </div>
            </Col>
          </Row>
        ) : (
          <Row className="justify-content-center">
            <Col lg="auto">
              <div className="p-5">
                <div className="text-center pt-5 pb-5">
                  <Logo withLink />
                </div>
                {children}
              </div>
            </Col>
          </Row>
        )}
      </Container>
      {withBackground ? (
        <div className="background background--half"></div>
      ) : (
        <>
          <div className="gradient gradient--top-right"></div>
          <div className="gradient gradient--bottom-left"></div>
        </>
      )}
    </div>
  );
};

export default PublicLayout;
