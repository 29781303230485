export enum Socials {
  instagram = "instagram",
}

export enum InstagramType {
  ad = "ad",
  feed = "feed",
  story = "story",
  reels = "reels",
}

export enum InstagramMediaType {
  image = "image",
  video = "video",
  carousel_album = "carousel_album",
  story = "story",
}
