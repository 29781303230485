import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";

import SocialLoginCard from "./SocialLoginCard";
import Button from "../../atoms/Button";
import Loader from "../../atoms/Loader";

import { selectLoading as selectShopLoading, selectShop } from "../../../stores/Shop/shop-slice";
import { fetchSocialAccountInstagrams } from "../../../stores/Shop/Social/SocialAccount/social-account-actions";
import {
  selectLoading as selectSocialAccountLoading,
  selectSocialAccountInstagram,
} from "../../../stores/Shop/Social/SocialAccount/social-accounts-slice";
import {
  selectLoading as selectSocialContentInstagramsLoading,
  selectSocialContentInstagrams,
} from "../../../stores/Shop/Social/SocialContent/social-contents-slice";
import { fetchSocialContentInstagrams } from "../../../stores/Shop/Social/SocialContent/social-content-actions";

interface SocialCardProps {
  className?: string;
  header: string;
}

const SocialCard: React.FC<SocialCardProps> = ({ className, header }: SocialCardProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const shop = useSelector(selectShop);
  const shopLoading = useSelector(selectShopLoading);

  const socialAccount = useSelector(selectSocialAccountInstagram);
  const socialAccountLoading = useSelector(selectSocialAccountLoading);

  const socialContents = useSelector(selectSocialContentInstagrams);
  const socialContentsLoading = useSelector(selectSocialContentInstagramsLoading);

  const isLoading = shopLoading || socialAccountLoading || socialContentsLoading;

  useEffect(() => {
    if (shop) {
      // @ts-ignore
      dispatch(fetchSocialAccountInstagrams({ shopId: shop.id }));
    }
  }, [shop, dispatch]);

  useEffect(() => {
    if (socialAccount) {
      // @ts-ignore
      dispatch(
        // @ts-ignore
        fetchSocialContentInstagrams({
          socialAccountId: socialAccount.id,
          count: 12,
        })
      );
    }
  }, [socialAccount, dispatch]);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Loader />
      </div>
    );
  } else if (!socialAccount || socialAccount.has_account_token_expired) {
    return <SocialLoginCard header={header} />;
  }

  return (
    <>
      <div>
        <h3 className="fw-bold">{header}</h3>
      </div>
      <Card className={`h-100 rounded-2 ${className}`}>
        <Card.Body className="bg-light-subtle d-flex justify-content-center align-items-center">
          <div>
            <div className="instagram-username-social-card">
              <p className="instagram-username-social-card-text"> @{socialAccount.account_name}</p>
            </div>
            <Row className="g-2">
              {socialContents.map((socialContent: any) => (
                <Col md={3} lg={6} xl={3} key={socialContent.id}>
                  <Image
                    src={socialContent.media_url}
                    rounded
                    fluid
                    alt={`@${socialContent.author_username}`}
                    className="object-fit-cover"
                    style={{ width: "150px", height: "150px" }}
                  />
                </Col>
              ))}
              {socialContents.length === 0 ? <Col>{t("publication.empty")}</Col> : null}
            </Row>
          </div>
        </Card.Body>
        {socialContents.length > 0 ? (
          <Card.Footer className="bg-light-subtle rounded-bottom-4 border-0 text-center pb-4">
            <Button className="rounded-2 fw-bold" theme="secondary" label={t("publication.actions.view")} to="/instagram/contents" />
          </Card.Footer>
        ) : null}
      </Card>
    </>
  );
};

export default SocialCard;
