import React from "react";
import { NavLink, Form } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Nav from "react-bootstrap/Nav";
import { House as HouseIcon } from "react-bootstrap-icons";
import { Instagram as SocialIcon } from "react-bootstrap-icons";
import { BookmarkStar as RewardIcon } from "react-bootstrap-icons";
import { GraphUp as AnalyticsIcon } from "react-bootstrap-icons";
import { Envelope as AutomationsIcon } from "react-bootstrap-icons";
import { Person as PersonIcon } from "react-bootstrap-icons";
import { Book as TutorialIcon } from "react-bootstrap-icons";
import { PeopleFill as AuthorIcon } from "react-bootstrap-icons";

import Submit from "../../atoms/Submit";

const Navigation = () => {
  const { t } = useTranslation();

  return (
    <Nav variant="pills" defaultActiveKey="/" className="nav--sticky flex-column pt-5">
      <Nav.Link as={NavLink} to="/" className="icon-link mb-2 text-black">
        <HouseIcon className="fs-6" />
        {t("nav.home")}
      </Nav.Link>
      <Nav.Link as={NavLink} to="/instagram/contents" className="icon-link mb-2 text-black">
        <SocialIcon className="fs-6" />
        {t("nav.instagram_contents")}
      </Nav.Link>
      <Nav.Link as={NavLink} to="/instagram/authors" className="icon-link mb-2 text-black">
        <AuthorIcon className="fs-6" />
        {t("nav.instagram_authors")}
      </Nav.Link>
      <Nav.Link as={NavLink} to="/rewards" className="icon-link mb-2 text-black">
        <RewardIcon className="fs-6" />
        {t("nav.config_rewards")}
      </Nav.Link>
      <Nav.Link as={NavLink} to="/analytics" className="icon-link mb-2 text-black">
        <AnalyticsIcon className="fs-6" />
        {t("nav.analytics")}
      </Nav.Link>
      {/* <Nav.Link as={NavLink} to="/automations" className="icon-link mb-2 text-black">
        <AutomationsIcon className="fs-6" />
        {t("nav.automations")}
      </Nav.Link> */}
      <Nav.Link as={NavLink} to="/account" className="icon-link mb-2 text-black">
        <PersonIcon className="fs-6" />
        {t("nav.account")}
      </Nav.Link>
      <Nav.Link as={NavLink} to="/tutorial" className="icon-link mb-2 text-black">
        <TutorialIcon className="fs-6" />
        {t("nav.tutorial")}
      </Nav.Link>
      <Form action="/logout" method="post" className="nav-logout">
        <Submit theme="tagether" label={t("actions.logout")} className="logout-button" />
      </Form>
    </Nav>
  );
};

export default Navigation;
