import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";
import Modal from "react-bootstrap/Modal";

import Button from "../../atoms/Button";

import instagramLogo from "../../../assets/images/instagram.svg";

import InstagramBusinessLogin from "./Instagram/InstagramBusinessLogin";

const SocialLoginCard = (props: { className?: string; header: string; description?: string }) => {
  const { t } = useTranslation();

  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <div>
        <h3 className="fw-bold">{props.header}</h3>
      </div>
      <Card className={`${props.className}`} >
        <Card.Body className="bg-light-subtle d-flex justify-content-center align-items-center rounded-2">
          <div className="text-center">
            <Image src={instagramLogo} roundedCircle className="mb-3" />
            <Card.Title>{t("social.instagram.title")}</Card.Title>
            <Card.Subtitle className="mb-3">{t("social.instagram.subtitle")}</Card.Subtitle>
            <Button className="rounded-2 fw-bold mb-3"
              theme="secondary" label={t("actions.login")} onClick={() => setModalShow(true)} />
            {props.description ? <Card.Text className="mb-2 ta-center" >{props.description}</Card.Text> : null}
          </div>
        </Card.Body>
      </Card>
      <Modal show={modalShow} onHide={() => setModalShow(false)} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{t("social.facebook.title_auth_card")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6 className="fw-bold">{t("social.facebook.subtitle_1_auth_card")}</h6>
          <p>{t("social.facebook.description_1_auth_card")}</p>

          <h6 className="fw-bold">{t("social.facebook.subtitle_2_auth_card")}</h6>
          <p>{t("social.facebook.description_2_auth_card")}</p>
        </Modal.Body>
        <Modal.Footer>
          <InstagramBusinessLogin />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SocialLoginCard;
