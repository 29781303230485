import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Form from "react-bootstrap/Form";

import rewardImage from "../../../../assets/images/reward.svg";

import RewardForm from "../../../organisms/Reward/RewardForm";
import ConfigRewardCard from "../../../organisms/Reward/ConfigRewardCard";
import SocialContentCard from "../../../organisms/Social/SocialContentCard";
import Button from "../../../atoms/Button";
import Loader from "../../../atoms/Loader";

import { selectLoading as selectShopLoading, selectShop } from "../../../../stores/Shop/shop-slice";
import {
  selectLoading as selectSocialContentLoading,
  selectSocialContentInstagram,
} from "../../../../stores/Shop/Social/SocialContent/social-contents-slice";
import { fetchSocialContentInstagram } from "../../../../stores/Shop/Social/SocialContent/social-content-actions";

const SocialContent = () => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const shop = useSelector(selectShop);
  const shopLoading = useSelector(selectShopLoading);

  const socialContent = useSelector(selectSocialContentInstagram);
  const socialContentLoading = useSelector(selectSocialContentLoading);

  const isLoading = shopLoading || socialContentLoading;

  const [reward, setReward] = useState(null);
  const [comment, setComment] = useState("");

  useEffect(() => {
    document.title = t("page.social_content.meta_title");
  }, []);

  useEffect(() => {
    if (shop && shop.quota && shop.quotaRemaining === 0) {
      navigate("/instagram/contents");
    }
  }, [shop]);

  useEffect(() => {
    // @ts-ignore
    dispatch(fetchSocialContentInstagram({ socialContentId: params.id }));
  }, [params.id]);

  useEffect(() => {
    if (socialContent) {
      setReward(socialContent.discount);
      setComment(t("publication.comment.message", { username: socialContent.author_username }));
    }
  }, [socialContent]);

  const handleFormSubmitted = (reward: any) => {
    setReward(reward);
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(comment);

    toast.success(t("publication.comment.copy"));
  };

  const handleContentClick = () => {
    if (!socialContent.permalink) {
      return;
    }

    window.open(socialContent.permalink, "_blank");
  };

  if (isLoading) {
    return (
      <Row>
        <Col>
          <div className="text-center mt-5">
            <Loader />
          </div>
        </Col>
      </Row>
    );
  }

  return (
    <>
      {socialContent ? (
        <Row className="justify-content-space-between gy-4">
          <Col md={12} lg={4}>
            <h4 className="fw-bold">{t("page.social_content.title")}</h4>
            <SocialContentCard socialContent={socialContent} />
          </Col>
          <Col lg={8}>
            {reward ? (
              <div className="h-100 w-100 d-flex justify-content-center align-items-center">
                <div>
                  <div className="text-center">
                    <Image src={rewardImage} alt="" className="mb-3" />
                    <h3 className="mb-3">{t("publication.success.title")}</h3>
                    <h4 className="mb-3">{t("publication.success.subtitle")}</h4>
                  </div>

                  <ConfigRewardCard configReward={reward} isSelected={true} />

                  <div className="mt-5">
                    <h5 className="fw-bold">{t("publication.comment.title")} :</h5>
                    <p>{t("publication.comment.info")}</p>
                    <Form.Group controlId="publicationComment">
                      <Form.Control as="textarea" rows={6} readOnly value={comment} />
                    </Form.Group>
                  </div>
                  <div className="text-end mt-3">
                    <Button theme="outline-tagether" to="/instagram/contents" label={t("actions.back")} className="me-2" />
                    <Button theme="primary" onClick={handleCopyClick} label={t("actions.copy")} className="me-2" />
                    {socialContent.permalink ? <Button theme="tagether" onClick={handleContentClick} label={t("social.instagram.view")} /> : null}
                  </div>
                </div>
              </div>
            ) : (
              <>
                <RewardForm socialContent={socialContent} onSubmitted={handleFormSubmitted} />
                <hr />
              </>
            )}
          </Col>
        </Row>
      ) : null}
    </>
  );
};

export default SocialContent;
