import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import ConfigRewardForm from "../../../organisms/Reward/ConfigRewardForm";

interface ConfigRewardAddProps {}

const ConfigRewardAdd: React.FC<ConfigRewardAddProps> = ({}: ConfigRewardAddProps) => {
  const params = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("page.config_rewards.create.meta_title");
  }, []);

  return (
    <>
      <h3 className="fw-bold">{t("page.config_rewards.subtitle")}</h3>
      <ConfigRewardForm type={params.type}/>
    </>
  );
};

export default ConfigRewardAdd;
