import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import Alert from "react-bootstrap/Alert";
import Card from "react-bootstrap/Card";

import ConfigRewardCard from "./ConfigRewardCard";
import Loader from "../../atoms/Loader";
import Button from "../../atoms/Button";

import { fetchConfigRewards } from "../../../stores/Shop/ConfigReward/config-reward-actions";
import { selectShop } from "../../../stores/Shop/shop-slice";
import { selectConfigRewards, selectError, selectLoading } from "../../../stores/Shop/ConfigReward/config-rewards-slice";

interface ConfigRewardListCardProps { }

const ConfigRewardListCard: FC<ConfigRewardListCardProps> = ({ }: ConfigRewardListCardProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const shop = useSelector(selectShop);

  const configRewards = useSelector(selectConfigRewards);
  const loading = useSelector(selectLoading);
  const error = useSelector(selectError);

  useEffect(() => {
    if (shop) {
      // @ts-ignore
      dispatch(fetchConfigRewards({ shopId: shop.id, count: 2 }));
    }
  }, [dispatch, shop]);

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Loader />
      </div>
    );
  }

  if (error) {
    return (
      <Alert variant="danger">
        <Alert.Heading as="h5">{t("errors.http")}</Alert.Heading>
      </Alert>
    );
  }

  return (
    <>
      <div>
        <h3 className="fw-bold">{t("nav.rewards")}</h3>
      </div>
      <Card className="rounded-2">
        <Card.Body className={`${configRewards.length === 0 ? "d-flex justify-content-center align-items-center" : ""}`}>
          {configRewards.map((configReward: any) => (
            <ConfigRewardCard key={configReward.id} configReward={configReward} isSelected={false} />
          ))}
          {configRewards.length === 0 ? (
            <div>
              <p className="empty-reward-box-text">{t("config_reward.empty")}</p>{" "}
            </div>
          ) : null}
        </Card.Body>
        <Card.Footer className="bg-light-subtle rounded-bottom-4 border-0 text-center pb-4">
          <Button
            className="rounded-2 fw-bold"
            theme="secondary"
            label={configRewards.length > 0 ? t("config_reward.action.show") : t("config_reward.action.create")}
            to="/rewards"
          />
        </Card.Footer>
      </Card>
    </>
  );
};

export default ConfigRewardListCard;
