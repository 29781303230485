import React from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

import instagramLogo from "../../../assets/images/instagram.svg";
import { Socials } from "../../../enums/SocialContentTypes";

interface SocialAuthorAccountProps {
  social: string;
  username: string;
  className?: string;
}

const SocialAuthorAccount: React.FC<SocialAuthorAccountProps> = ({ social, username, className }: SocialAuthorAccountProps) => {
  const socialIcons: Record<Socials, string> = {
    instagram: instagramLogo,
  };

  const socialIcon = socialIcons[social as Socials] || null;

  return (
    <div className={className ?? ""}>
      <Row className="justify-content-between align-items-center">
        {socialIcon ? (
          <Col xs={2}>
            <div className="position-relative me-3" style={{ width: "40px" }}>
              <Image src={socialIcon} roundedCircle width="40px" height="40px" className="object-fit-cover" alt={social} />
            </div>
          </Col>
        ) : null}

        <Col>
          <div className="d-flex flex-column text-center">
            <span className="fw-bold">@{username}</span>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SocialAuthorAccount;
