import SocialContents from "../components/pages/Shop/Social/SocialContents";
import SocialContent from "../components/pages/Shop/Social/SocialContent";
import Rewards from "../components/pages/Shop/Reward/Rewards";
import AccountSocials from "../components/pages/Shop/Account/AccountSocials";
import AccountInvoices from "../components/pages/Shop/Account/AccountInvoices";
import AccountOffer from "../components/pages/Shop/Account/AccountOffer";
import Tutorial from "../components/pages/Tutorial";
import SocialLayout from "../components/layouts/SocialLayout";
import AccountLayout from "../components/layouts/AccountLayout";
import AccountPassword from "../components/pages/Shop/Account/AccountPassword";
import ConfigRewardAdd from "../components/pages/Shop/Reward/ConfigRewardAdd";
import ConfigRewardEdit, { loader as configRewardEditLoader } from "../components/pages/Shop/Reward/ConfigRewardEdit";
import ConfigRewards from "../components/pages/Shop/Reward/ConfigRewards";
import Automations from "../components/pages/Shop/Automation/Automations";
import AutomationEdit, { loader as automationEditLoader } from "../components/pages/Shop/Automation/AutomationEdit";
import AutomationAdd from "../components/pages/Shop/Automation/AutomationAdd";
import SocialAuthors from "../components/pages/Shop/Social/SocialAuthors";
import SocialAuthorLayout from "../components/layouts/SocialAuthorLayout";
import SocialAuthorContents from "../components/pages/Shop/Social/SocialAuthorContents";
import SocialAuthorRewards from "../components/pages/Shop/Social/SocialAuthorRewards";

export default [
  {
    path: ":social/contents",
    element: <SocialLayout />,
    children: [
      {
        index: true,
        element: <SocialContents />,
      },
      {
        path: ":id",
        element: <SocialContent />,
      },
    ],
  },
  {
    path: ":social/authors",
    element: <SocialLayout />,
    children: [
      {
        index: true,
        element: <SocialAuthors />,
      },
      {
        path: ":username",
        element: <SocialAuthorLayout />,
        children: [
          {
            index: true,
            element: <SocialAuthorContents />,
          },
          {
            path: "rewards",
            element: <SocialAuthorRewards />,
          },
        ],
      },
    ],
  },
  {
    path: "rewards",
    element: <SocialLayout />,
    children: [
      {
        index: true,
        element: <ConfigRewards />,
      },
      {
        path: ":type/add",
        element: <ConfigRewardAdd />,
      },
      {
        path: ":type/:id",
        element: <ConfigRewardEdit />,
        loader: configRewardEditLoader,
      },
    ],
  },
  {
    path: "analytics",
    element: <SocialLayout />,
    children: [
      {
        index: true,
        element: <Rewards />,
      },
    ],
  },
  // {
  //   path: "automations",
  //   element: <SocialLayout />,
  //   children: [
  //     {
  //       index: true,
  //       element: <Automations />,
  //     },
  //     {
  //       path: ":type/:social/:socialContentType/add",
  //       element: <AutomationAdd />,
  //     },
  //     // {
  //     //   path: ":id",
  //     //   element: <AutomationEdit />,
  //     //   loader: automationEditLoader,
  //     // },
  //   ],
  // },
  {
    path: "account",
    element: <AccountLayout />,
    children: [
      {
        index: true,
        element: <AccountSocials />,
      },

      {
        path: "offer",
        element: <AccountOffer />,
      },
      {
        path: "invoices",
        element: <AccountInvoices />,
      },
      {
        path: "password",
        element: <AccountPassword />,
      },
    ],
  },
  {
    path: "tutorial",
    element: <Tutorial />,
  },
];
