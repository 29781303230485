import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../types/store";
import { createAutomation, deleteAutomation, fetchAutomations, suspendAutomation, updateAutomation } from "./automation-actions";

interface AutomationsState {
  automations: Array<any>; // @todo
  totalItems: number;
  loading: boolean;
  error: string | null;
}

const initialState: AutomationsState = {
  automations: [],
  totalItems: 0,
  loading: false,
  error: null,
};

const automationsSlice = createSlice({
  name: "automations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAutomations.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.totalItems = 0;
      })
      .addCase(fetchAutomations.fulfilled, (state, action) => {
        state.loading = false;
        state.automations = action.payload["hydra:member"];
        state.totalItems = action.payload["hydra:totalItems"];
      })
      .addCase(fetchAutomations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
        state.totalItems = 0;
      })
      .addCase(createAutomation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createAutomation.fulfilled, (state, action) => {
        state.loading = false;
        state.automations.push(action.payload);
      })
      .addCase(createAutomation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
      })
      .addCase(updateAutomation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateAutomation.fulfilled, (state, action) => {
        state.loading = false;
        state.automations = state.automations.map((automation: any) => (automation.id === action.payload.id ? action.payload : automation));
      })
      .addCase(updateAutomation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
      })
      .addCase(deleteAutomation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteAutomation.fulfilled, (state, action) => {
        state.loading = false;

        const { automationId } = action.payload;

        if (automationId) {
          state.automations = state.automations.filter((automation: any) => automation.id !== automationId);
        }
      })
      .addCase(deleteAutomation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
      })
      .addCase(suspendAutomation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(suspendAutomation.fulfilled, (state, action) => {
        state.loading = false;
        state.automations = state.automations.map((automation: any) => (automation.id === action.payload.id ? action.payload : automation));
      })
      .addCase(suspendAutomation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
      });
  },
});

export const {} = automationsSlice.actions;

export const selectAutomations = (state: RootState) => state.shopAutomations.automations;
export const selectTotalItems = (state: RootState) => state.shopAutomations.totalItems;
export const selectLoading = (state: RootState) => state.shopAutomations.loading;
export const selectError = (state: RootState) => state.shopAutomations.error;

export default automationsSlice.reducer;
