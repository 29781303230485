import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Nav from "react-bootstrap/Nav";

interface SocialAuthorNavigationProps {
  social: string;
  username: string;
}

const SocialAuthorNavigation: React.FC<SocialAuthorNavigationProps> = ({ social, username }: SocialAuthorNavigationProps) => {
  const { t } = useTranslation();

  const path = `/${social}/authors/${username}`;

  return (
    <Nav variant="underline" defaultActiveKey={path} className="pt-3 border-bottom">
      <Nav.Item>
        <Nav.Link as={NavLink} to={path} end className="text-black">
          {t("social_authors.nav.contents")}
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={NavLink} to={path + "/rewards"} end className="text-black">
          {t("social_authors.nav.rewards")}
        </Nav.Link>
      </Nav.Item>
    </Nav>
  );
};

export default SocialAuthorNavigation;
