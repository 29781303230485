import React from "react";
import { Link } from "react-router-dom";

import { Button as BootstrapButton } from "react-bootstrap";

interface ButtonProps {
  className?: string;
  theme?: string;
  to?: string;
  href?: string;
  label: string;
  onClick?: any;
}

const Button: React.FC<ButtonProps> = ({ className, theme, to, href, label, onClick }: ButtonProps) => {
  return (
    <>
      {to ? (
        <Link className={`btn btn-${theme} ${className}`} to={to} onClick={(e) => e.stopPropagation()}>
          {label}
        </Link>
      ) : (
        <BootstrapButton variant={theme} onClick={onClick} className={className}>
          {label}
        </BootstrapButton>
      )}
    </>
  );
};

export default Button;
