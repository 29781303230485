import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";

import { Image as ImageIcon } from "react-bootstrap-icons";
import { PlayBtnFill as VideoIcon } from "react-bootstrap-icons";
import { CameraVideoFill as StoryIcon } from "react-bootstrap-icons";
import { HeartFill as LikeIcon } from "react-bootstrap-icons";
import { ChatFill as CommentIcon } from "react-bootstrap-icons";

import checkIconImage from "../../../assets/images/icons/icon-check.png";

import Button from "../../atoms/Button";

import { selectShop } from "../../../stores/Shop/shop-slice";
import { InstagramMediaType, InstagramType } from "../../../enums/SocialContentTypes";

interface SocialContentCardProps {
  socialContent: any;
  onList?: boolean;
}

const SocialContentCard: React.FC<SocialContentCardProps> = ({ socialContent, onList }: SocialContentCardProps) => {
  const { t } = useTranslation();
  const shop = useSelector(selectShop);

  const [hasQuota, setHasQuota] = useState(true);

  useEffect(() => {
    if (shop) {
      setHasQuota(shop.quota && shop.quotaRemaining > 0);
    }
  }, [shop]);

  const handleContentClick = () => {
    if (!socialContent.permalink) {
      return;
    }

    window.open(socialContent.permalink, "_blank");
  };

  const reward = socialContent.discount;
  const isVideo = socialContent.media_type === InstagramMediaType.video;
  const socialContentTypeIcons: Record<InstagramType, JSX.Element> = {
    ad: <ImageIcon />,
    feed: <ImageIcon />,
    story: <StoryIcon />,
    reels: <VideoIcon />,
  };
  const socialContentTypeIcon = socialContentTypeIcons[socialContent.type as InstagramType] || "";

  let cardProps = {};
  if (socialContent.permalink) {
    cardProps = {
      ...cardProps,
      onClick: handleContentClick,
      style: { cursor: "pointer" },
    };
  }

  return (
    <Card {...cardProps}>
      <Card.Img
        variant="middle"
        src={socialContent.media_url}
        alt={socialContent.caption}
        className={`object-fit-cover ${reward ? "opacity-50" : ""}`}
        style={{ height: onList ? "412px" : "" }}
      />
      {onList ? (
        <div className="social-detail-card">
          <span className="fw-bold text-truncate">@{socialContent.author_username.slice(0, 20)}</span>
          <div className="d-flex align-items-center justify-content-between">
            <div className="text-start">
              <div className="d-flex align-items-center mt-1">
                {socialContentTypeIcon}
                <span className="fw-bold ms-1">{t(`social.instagram.type.${socialContent.type}`)}</span>
              </div>
              <div className="d-flex align-items-center mt-1">
                <div className="d-flex align-items-center">
                  <LikeIcon />
                  <span className="fw-bold ms-1">{socialContent.like_count}</span>
                </div>
                <div className="d-flex align-items-center ms-2">
                  <CommentIcon />
                  <span className="fw-bold ms-1">{socialContent.comment_count}</span>
                </div>
              </div>
            </div>
            {reward ? (
              <Image src={checkIconImage} alt={t("publication.rewarded")} width="32px" />
            ) : (
              <Button
                theme="tagether"
                to={`/instagram/contents/${socialContent.id}`}
                label={t("actions.retain")}
                className={`btn-sm fw-bold justify-content-center ${hasQuota ? "disabled" : ""}`}
              />
            )}
          </div>
        </div>
      ) : null}
    </Card>
  );
};

export default SocialContentCard;
