import React from "react";
import { Outlet } from "react-router-dom";

import AccountNavigation from "../organisms/Layout/AccountNavigation";
import SocialHeader from "../organisms/Social/SocialHeader";
import { Socials } from "../../enums/SocialContentTypes";

const AccountLayout = () => {
  return (
    <>
      <SocialHeader social={Socials.instagram} />
      <AccountNavigation />
      <div className="py-3">
        <Outlet />
      </div>
    </>
  );
};

export default AccountLayout;
