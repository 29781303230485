import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { format } from "date-fns";
import fr from "date-fns/locale/fr";

import Badge from "react-bootstrap/Badge";
import Image from "react-bootstrap/Image";

import Table from "../../molecules/Table";
import Loader from "../../atoms/Loader";
import Button from "../../atoms/Button";

import rewardsImage from "../../../assets/images/rewards.svg";

import { RewardTypeThemes, getRewardTypeTheme } from "../../../enums/RewardTypes";
import {
  selectRewards,
  selectLoading as selectRewardsLoading,
  selectTotalItems as selectRewardsTotalItems,
  selectError as selectRewardsError,
} from "../../../stores/Shop/Reward/rewards-slice";
import { fetchRewards } from "../../../stores/Shop/Reward/reward-actions";
import { selectShop, selectLoading as selectShopLoading } from "../../../stores/Shop/shop-slice";

interface RewardTableProps {
  options?: { [key: string]: any };
  theme?: string;
  withPaginate?: boolean;
}

const RewardTable: FC<RewardTableProps> = ({ options, theme, withPaginate }: RewardTableProps) => {
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const shop = useSelector(selectShop);
  const shopLoading = useSelector(selectShopLoading);

  const rewards = useSelector(selectRewards);
  const rewardsLoading = useSelector(selectRewardsLoading);
  const rewardsTotalItems = useSelector(selectRewardsTotalItems);
  const rewardsError = useSelector(selectRewardsError);

  const isLoading = shopLoading || rewardsLoading;

  const [page, setPage] = useState(1);

  const handleContentClick = (reward: any) => {
    if (!reward.permalink) {
      return;
    }

    window.open(reward.permalink, "_blank");
  };

  const handleRewardPaginate = (page: number) => {
    setPage(page);
  };

  useEffect(() => {
    if (shop) {
      // @ts-ignore
      dispatch(fetchRewards({ shopId: shop.id, page: page, options: options }));
    }
  }, [page, shop, dispatch]);

  if (rewardsError) {
    toast.error(t("errors.http"));
  }

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Loader />
      </div>
    );
  }

  const headers = [
    {
      content: t("reward.ambassador.label"),
      className: "align-middle text-center rounded-top-start",
    },
    {
      content: t("reward.media_type.label"),
      className: "align-middle text-center",
    },
    {
      content: t("reward.url.label"),
      className: "align-middle text-center",
    },
    {
      content: t("reward.assigned.label"),
      className: "align-middle text-center",
    },
    {
      content: t("reward.label"),
      className: "align-middle text-center",
    },
    {
      content: t("reward.value.label"),
      className: "align-middle text-center",
    },
    {
      content: t("reward.status.label"),
      className: "align-middle text-center rounded-top-end",
    },
  ];

  const locale = fr;
  const rows: Array<Array<any>> = rewards.map((reward: any) => {
    const socialUsername: string = reward.username ? "@" + reward.username : "-";
    const socialMedia: string = reward.mediaId;
    const socialMediaType: string = reward.mediaType;
    const socialMediaTypeLabel: string = socialMediaType ? t(`social.instagram.media_type.${socialMediaType}`) : "-";
    const date: string = format(new Date(reward.createdAt), "MMMM - yy", { locale });
    const type: string = reward.type;
    const typeLabel: string = t(`reward.type.${type}`);
    const typeTheme: string = getRewardTypeTheme(type as keyof typeof RewardTypeThemes);
    const value: string = reward.description ?? reward.code ?? reward.amount_in_percent + " %";
    const statusLabel: string = reward.usedAt ? t("reward.used_at.not_null") : t("reward.used_at.null");

    return [
      {
        content: socialUsername,
        className: "content-table align-middle",
      },
      {
        content: socialMediaTypeLabel,
        className: "content-table align-middle text-center",
      },
      {
        content: socialMedia ? (
          <Button
            theme="dark"
            className="btn-sm fw-bold rounded-2 content-table-media align-middle text-center"
            label={t("actions.view")}
            onClick={() => handleContentClick(reward)}
          />
        ) : (
          "-"
        ),
        className: "content-table align-middle text-center",
      },
      {
        content: date,
        className: "text-capitalize text-center",
      },
      {
        content: (
          <Badge bg={typeTheme} className="inline-block align-middle text-center">
            {typeLabel}
          </Badge>
        ),
        className: "align-middle text-center",
      },
      {
        content: value,
        className: "text-center content-table align-middle",
      },
      {
        content: statusLabel,
        className: "fw-bold content-table align-middle text-center",
      },
    ];
  });

  let tableProps: any = {
    headers: headers,
    rows: rows,
    loading: isLoading,
    theme: theme,
  };

  if (withPaginate) {
    tableProps.paginate = { active: page, pages: Math.ceil(rewardsTotalItems / 10), onPaginate: handleRewardPaginate };
  }

  return (
    <>
      <Table {...tableProps} />
      <div className="text-center p-3">
        {rewards.length === 0 ? (
          <>
            <Image src={rewardsImage} fluid alt="" />
            <p className="mt-3">{t("reward.empty")}</p>
            <Button className="rounded-2" theme={theme} label={t("actions.assign")} to="/instagram/contents" />
          </>
        ) : (
          <>
            {location.pathname === "/" ? (
              <Button className="rounded-2 fw-bold" theme="secondary" label={t("actions.view_more")} to="/analytics" />
            ) : null}
          </>
        )}
      </div>
    </>
  );
};

export default RewardTable;
