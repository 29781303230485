import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useParams } from "react-router-dom";
import SocialContentList from "../../../organisms/Social/SocialContentList";

const SocialAuthorContents = () => {
  const { t } = useTranslation();
  const params = useParams();
  const username = params.username;
  const options = {
    authorUsername: username,
  };

  useEffect(() => {
    document.title = t("page.social_authors.meta_title");
  }, []);

  return (
    <>
      <SocialContentList options={options} />
    </>
  );
};

export default SocialAuthorContents;
