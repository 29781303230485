import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../../types/store";
import { logoutSocialAccount, fetchSocialAccountInstagrams } from "./social-account-actions";

interface SocialAccountState {
  socialAccount: any; // @todo
  loading: boolean;
  error: string | null;
}

const initialState: SocialAccountState = {
  socialAccount: null,
  loading: false,
  error: null,
};

const socialAccountsSlice = createSlice({
  name: "socialAccounts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSocialAccountInstagrams.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSocialAccountInstagrams.fulfilled, (state, action) => {
        state.loading = false;
        state.socialAccount = action.payload["hydra:member"][0] ?? null;
      })
      .addCase(fetchSocialAccountInstagrams.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
      })
      .addCase(logoutSocialAccount.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(logoutSocialAccount.fulfilled, (state, action) => {
        state.loading = false;
        state.socialAccount = null;
      })
      .addCase(logoutSocialAccount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
      });
  },
});

export const {} = socialAccountsSlice.actions;

export const selectSocialAccountInstagram = (state: RootState) => state.shopSocialAccounts.socialAccount;
export const selectLoading = (state: RootState) => state.shopSocialAccounts.loading;
export const selectError = (state: RootState) => state.shopSocialAccounts.error;

export default socialAccountsSlice.reducer;
