import { createAsyncThunk } from "@reduxjs/toolkit";

import { getAuthToken } from "../../../../services/auth";

const buildUrl = (baseUrl: string, params: Record<string, any>): string => {
  const searchParams = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    if (value !== undefined && value !== null && value !== "") {
      searchParams.append(key, value.toString());
    }
  });

  return `${baseUrl}?${searchParams.toString()}`;
};

export const fetchSocialAuthors = createAsyncThunk(
  "socialAuthors/fetchSocialAuthors",
  async ({ socialAccountId, count, page }: { socialAccountId: string; count?: number; page?: number }) => {
    const url = buildUrl("/social_content_instagrams/author_metrics", {
      socialAccount: socialAccountId,
      itemsPerPage: count || 12,
      page: page || 1,
    });

    const token = getAuthToken();
    const response = await fetch(
      new Request(process.env.REACT_APP_API_ENTRYPOINT + url, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }),
        cache: "no-cache",
      })
    );

    if (!response.ok) {
      throw new Error("Erreur lors de la récupération des créateurs de contenus Instagram");
    }

    const data = await response.json();

    return data;
  }
);

export const updateSocialAuthor = createAsyncThunk(
  "socialAuthors/updateSocialAuthor",
  async ({ socialAccountId, username, email }: { socialAccountId: string; username: string; email: string }) => {
    const token = getAuthToken();
    const url = buildUrl("/social_content_instagrams/patch_email_by_username", {
      socialAccount: socialAccountId,
    });

    const response = await fetch(
      new Request(process.env.REACT_APP_API_ENTRYPOINT + url, {
        method: "PATCH",
        body: JSON.stringify({
          authorUsername: username,
          authorEmail: email,
        }),
        headers: new Headers({
          "Content-Type": "application/vnd.api+json",
          Authorization: "Bearer " + token,
        }),
      })
    );

    if (!response.ok) {
      throw new Error("Erreur lors de l'ajout de l'email de l'ambassadeur");
    }

    const data = await response.json();

    return data;
  }
);
