import React from "react";

import Button from "react-bootstrap/Button";

import Loader from "./Loader";

interface SubmitProps {
  theme?: string;
  label: any;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
}

const Submit: React.FC<SubmitProps> = ({ theme, label, disabled, loading, className }: SubmitProps) => {
  return (
    <Button variant={theme} type="submit" disabled={disabled || loading} className={className}>
      {loading ? <Loader isSmall /> : label}
    </Button>
  );
};

export default Submit;
