import { createAsyncThunk } from "@reduxjs/toolkit";

import { getAuthToken } from "../../../services/auth";

const buildUrl = (baseUrl: string, params: Record<string, any>): string => {
  const searchParams = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    if (value !== undefined && value !== null && value !== "") {
      searchParams.append(key, value.toString());
    }
  });

  return `${baseUrl}?${searchParams.toString()}`;
};

export const fetchRewards = createAsyncThunk(
  "rewards/fetch",
  async ({ shopId, count, page, options }: { shopId: string; count?: number; page?: number; options?: { [key: string]: any } }) => {
    const token = getAuthToken();
    const url = buildUrl(`/shops/${shopId}/discounts`, {
      "order[createdAt]": "desc",
      itemsPerPage: count || 10,
      page: page || 1,
      ...options,
    });

    const response = await fetch(
      new Request(process.env.REACT_APP_API_ENTRYPOINT + url, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }),
        cache: "no-cache",
      })
    );

    if (!response.ok) {
      throw new Error("Erreur lors de la récupération des récompenses attribuées");
    }

    const data = await response.json();

    return data;
  }
);
