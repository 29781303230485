import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Filter from "../atoms/Filter";
import Button from "../atoms/Button";

interface FilterOption {
  key: string;
  type: "select" | "range";
  label: string;
  options?: { value: string; label: string }[];
}

interface FiltersProps {
  filters: FilterOption[];
  onFilterChange: (filters: any) => void;
  debounceTime?: number;
}

const Filters: React.FC<FiltersProps> = ({ filters, onFilterChange, debounceTime = 500 }: FiltersProps) => {
  const { t } = useTranslation();

  const initialFilters: any = {};
  const [selectedFilters, setSelectedFilters] = useState(initialFilters);
  const [debouncedFilters, setDebouncedFilters] = useState(initialFilters);

  const handleFilterChange = (key: string, value: any) => {
    const currentValue = selectedFilters[key];
    const updatedValue = typeof value === "object" ? { ...currentValue, ...value } : value;

    const updatedFilters = { ...selectedFilters, [key]: updatedValue };
    setSelectedFilters(updatedFilters);
  };

  const handleResetClick = () => {
    setSelectedFilters(initialFilters);
    setDebouncedFilters(initialFilters);
    onFilterChange(initialFilters);
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedFilters(selectedFilters);
    }, debounceTime);

    return () => {
      clearTimeout(handler);
    };
  }, [selectedFilters, debounceTime]);

  useEffect(() => {
    onFilterChange(debouncedFilters);
  }, [debouncedFilters]);

  return (
    <div className="d-flex justify-content-between flex-sm-column flex-lg-row">
      {filters.map((filter) => (
        <Filter
          key={filter.key}
          className="me-2 mb-sm-2 mb-lg-0"
          type={filter.type}
          label={filter.label}
          value={selectedFilters[filter.key]}
          onChange={(value) => handleFilterChange(filter.key, value)}
          options={filter.options ?? []}
        />
      ))}
      <Button className="btn-sm" label={t("actions.reset")} onClick={handleResetClick} />
    </div>
  );
};

export default Filters;
