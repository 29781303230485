import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import RewardTable from "../../../organisms/Reward/RewardTable";

const Rewards = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("page.rewards.meta_title");
  }, []);

  return (
    <div className="py-3">
      <h3 className="fw-bold mb-2">{t("page.rewards.title")}</h3>
      <RewardTable theme="dark-blue" withPaginate />
    </div>
  );
};

export default Rewards;
