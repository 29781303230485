import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import thunkMiddleware from "redux-thunk";

import ownerReducer from "./User/owner-slice";
import shopReducer from "./Shop/shop-slice";
import configRewardsReducer from "./Shop/ConfigReward/config-rewards-slice";
import rewardsReducer from "./Shop/Reward/rewards-slice";
import socialAccountsReducer from "./Shop/Social/SocialAccount/social-accounts-slice";
import socialContentsReducer from "./Shop/Social/SocialContent/social-contents-slice";
import socialAuthorsReducer from "./Shop/Social/SocialAuthor/social-authors-slice";
import automationReducer from "./Shop/Automation/automations-slice";

const store = configureStore({
  reducer: {
    userOwner: ownerReducer,
    shop: shopReducer,
    shopConfigRewards: configRewardsReducer,
    shopRewards: rewardsReducer,
    shopSocialAccounts: socialAccountsReducer,
    shopSocialContents: socialContentsReducer,
    shopSocialAuthors: socialAuthorsReducer,
    shopAutomations: automationReducer,
  },
  middleware: [...getDefaultMiddleware(), thunkMiddleware],
});

export default store;
