import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../types/store";
import { fetchCategories, fetchShop } from "./shop-actions";

interface ShopState {
  shop: any; // @todo
  categories: Array<any>; // @todo
  loading: boolean;
  error: string | null;
}

const initialState: ShopState = {
  shop: null,
  categories: [],
  loading: false,
  error: null,
};

const shopSlice = createSlice({
  name: "shop",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchShop.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchShop.fulfilled, (state, action) => {
        state.loading = false;
        state.shop = action.payload;
      })
      .addCase(fetchShop.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
      })
      .addCase(fetchCategories.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.categories = action.payload["hydra:member"];
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
      });
  },
});

export const {} = shopSlice.actions;

export const selectShop = (state: RootState) => state.shop.shop;
export const selectCategories = (state: RootState) => state.shop.categories;
export const selectLoading = (state: RootState) => state.shop.loading;
export const selectError = (state: RootState) => state.shop.error;

export default shopSlice.reducer;
