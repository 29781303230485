import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Loader from "../../atoms/Loader";
import Pagination from "../../molecules/Pagination";
import SocialLoginCard from "../../organisms/Social/SocialLoginCard";
import SocialContentCard from "../../organisms/Social/SocialContentCard";

import { fetchSocialContentInstagrams } from "../../../stores/Shop/Social/SocialContent/social-content-actions";
import {
  selectSocialContentInstagrams,
  selectTotalItems as selectSocialContentsTotalItems,
  selectLoading as selectSocialContentsLoading,
  selectError as selectSocialContentsError,
} from "../../../stores/Shop/Social/SocialContent/social-contents-slice";
import {
  selectLoading as selectSocialAccountLoading,
  selectSocialAccountInstagram,
} from "../../../stores/Shop/Social/SocialAccount/social-accounts-slice";
import Filters from "../../molecules/Filters";
import Button from "../../atoms/Button";
import { InstagramType } from "../../../enums/SocialContentTypes";
import { pullContentsSocialAccountInstagram } from "../../../stores/Shop/Social/SocialAccount/social-account-actions";

interface FilterOption {
  key: string;
  type: "select" | "range";
  label: string;
  options?: { value: string; label: string }[];
}

interface SocialContentListProps {
  options?: { [key: string]: any };
}

const SocialContentList: React.FC<SocialContentListProps> = ({ options }: SocialContentListProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const socialAccount = useSelector(selectSocialAccountInstagram);
  const socialAccountLoading = useSelector(selectSocialAccountLoading);

  const socialContents = useSelector(selectSocialContentInstagrams);
  const socialContentsLoading = useSelector(selectSocialContentsLoading);
  const socialContentsTotalItems = useSelector(selectSocialContentsTotalItems);
  const socialContentsError = useSelector(selectSocialContentsError);

  const isLoading = socialAccountLoading || socialContentsLoading;

  const [page, setPage] = useState(1);

  const socialContentTypes = Object.keys(InstagramType).map((key: string) => ({
    value: key,
    label: t(`social.instagram.type.${key}`),
  }));

  const filterOptions: FilterOption[] = [
    {
      key: "type",
      type: "select",
      label: "Type de contenus",
      options: socialContentTypes,
    },
    { key: "like_count", type: "range", label: "Likes" },
  ];

  const handleFilterChange = (filters: any) => {
    setPage(1);

    // @ts-ignore
    dispatch(fetchSocialContentInstagrams({ socialAccountId: socialAccount.id, page: page, options: options, ...filters }));
  };

  const handleSocialContentPaginate = (page: number) => {
    setPage(page);
  };

  const handlePullContentsClick = () => {
    if (socialAccount) {
      // @ts-ignore
      dispatch(pullContentsSocialAccountInstagram({ socialAccountId: socialAccount.id }));
      toast.info(t("page.social_contents.pull.pending"), { autoClose: 1000 });
    }
  };

  useEffect(() => {
    if (socialAccount) {
      // @ts-ignore
      dispatch(fetchSocialContentInstagrams({ socialAccountId: socialAccount.id, page: page, options: options }));
    }
  }, [socialAccount, page, dispatch]);

  if (!socialAccount || socialAccount.has_account_token_expired) {
    return (
      <Row className="justify-content-center">
        <Col lg={6}>
          <SocialLoginCard header={t("social.instagram.login")} description={t("social.help")} className="text-center mt-5" />
        </Col>
      </Row>
    );
  }

  if (socialContentsError) {
    toast.error(t("errors.http"));
  }

  const yesterday = () => {
    const date = new Date();
    date.setDate(date.getDate() - 1);

    return date;
  };

  return (
    <>
      <Row className="g-4 mb-4 align-items-center justify-content-end">
        <Col className="fs-6 fst-italic">
          {/* <Button className="btn-sm" label={t("page.social_contents.pull.action")} onClick={handlePullContentsClick} /> */}
          {t("page.social_contents.pull.description", { yesterday: yesterday().toLocaleDateString() })}
        </Col>
        <Col xl={8}>
          <Filters filters={filterOptions} onFilterChange={handleFilterChange} />
        </Col>
      </Row>
      <Row className="g-4">
        {socialContents.map((socialContent: any, index: number) => (
          <Col md={6} lg={6} xl={3} key={index}>
            <SocialContentCard socialContent={socialContent} onList />
          </Col>
        ))}
      </Row>
      {isLoading ? (
        <Row>
          <Col>
            <div className="text-center mt-5">
              <Loader />
            </div>
          </Col>
        </Row>
      ) : (
        <>
          {socialContents.length > 0 ? (
            <Row>
              <Col>
                <div className="text-center mt-5">
                  <hr />
                  <Pagination paginate={{ active: page, pages: Math.ceil(socialContentsTotalItems / 12), onPaginate: handleSocialContentPaginate }} />
                </div>
              </Col>
            </Row>
          ) : (
            <Row className="mt-5">
              <Col className="text-center">{t("publication.empty")}</Col>
            </Row>
          )}
        </>
      )}
    </>
  );
};

export default SocialContentList;
