import React from "react";

import { Pagination as BootstrapPagination } from "react-bootstrap";

interface PaginationProps {
  theme?: string;
  paginate: {
    active: number;
    pages: number;
    onPaginate: any;
  };
}

const Pagination: React.FC<PaginationProps> = ({ theme, paginate }: PaginationProps) => {
  let paginateItems = [];
  for (let number = 1; number <= paginate.pages; number++) {
    paginateItems.push(
      <BootstrapPagination.Item key={number} active={number === paginate.active} onClick={() => paginate.onPaginate(number)}>
        {number}
      </BootstrapPagination.Item>
    );
  }

  return (
    <>
      {paginateItems.length > 0 ? (
        <div className="d-flex justify-content-end">
          <BootstrapPagination className={`pagination-${theme}`}>{paginateItems}</BootstrapPagination>
        </div>
      ) : null}
    </>
  );
};

export default Pagination;
