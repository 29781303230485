import { createAsyncThunk } from "@reduxjs/toolkit";

import { getAuthToken } from "../../../../services/auth";
import { Socials } from "../../../../enums/SocialContentTypes";

export const fetchSocialAccountInstagrams = createAsyncThunk(
  "socialAccounts/fetchSocialAccountInstagrams",
  async ({ shopId }: { shopId: string }) => {
    const social = Socials.instagram;
    const token = getAuthToken();
    const response = await fetch(
      new Request(process.env.REACT_APP_API_ENTRYPOINT + `/shops/${shopId}/social_accounts?socialId=${social}`, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }),
        cache: "no-cache",
      })
    );

    if (!response.ok) {
      throw new Error("Erreur lors de la récupération du compte Instagram");
    }

    const data = await response.json();

    return data;
  }
);

export const pullContentsSocialAccountInstagram = createAsyncThunk(
  "socialAccounts/pullContentsSocialAccountInstagram",
  async ({ socialAccountId }: { socialAccountId: string }) => {
    const token = getAuthToken();
    const response = await fetch(
      new Request(process.env.REACT_APP_API_ENTRYPOINT + `/social_account_instagrams/${socialAccountId}/pull_contents`, {
        method: "PUT",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }),
      })
    );

    if (!response.ok) {
      throw new Error("Erreur lors de la récupération des contenus du compte Instagram");
    }

    const data = await response.json();

    return data;
  }
);

export const logoutSocialAccount = createAsyncThunk(
  "socialAccounts/logoutSocialAccount",
  async ({ socialAccountId }: { socialAccountId: string }) => {
    const token = getAuthToken();
    const response = await fetch(
      new Request(process.env.REACT_APP_API_ENTRYPOINT + `/social_accounts/${socialAccountId}/logout`, {
        method: "PUT",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }),
      })
    );

    if (!response.ok) {
      throw new Error("Erreur lors de la déconnexion du compte Instagram");
    }

    const data = await response.json();

    return data;
  }
);
