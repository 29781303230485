import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

import checkIconImage from "../../../assets/images/icons/icon-check.png";

import Loader from "../../atoms/Loader";

import { selectLoading as selectShopLoading, selectShop } from "../../../stores/Shop/shop-slice";
import { fetchSocialAccountInstagrams } from "../../../stores/Shop/Social/SocialAccount/social-account-actions";
import {
  selectLoading as selectSocialAccountLoading,
  selectSocialAccountInstagram,
} from "../../../stores/Shop/Social/SocialAccount/social-accounts-slice";

interface SocialAccountProps {
  social: string;
  className?: string;
  withDetails?: boolean;
  withStatus?: boolean;
}

const SocialAccount: React.FC<SocialAccountProps> = ({ social, className, withDetails, withStatus }: SocialAccountProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const shop = useSelector(selectShop);
  const shopLoading = useSelector(selectShopLoading);

  const socialAccountInstagram = useSelector(selectSocialAccountInstagram);
  const socialAccountLoading = useSelector(selectSocialAccountLoading);

  const isLoading = shopLoading || socialAccountLoading;

  useEffect(() => {
    if (shop) {
      // @ts-ignore
      dispatch(fetchSocialAccountInstagrams({ shopId: shop.id }));
    }
  }, [shop, dispatch]);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center p-3">
        <Loader isSmall />
      </div>
    );
  }

  return (
    <div className={className ?? ""}>
      {socialAccountInstagram && !socialAccountInstagram.has_account_token_expired ? (
        <Row className="justify-content-between align-items-center">
          <Col sm={12} xl={6}>
            <div className="d-flex align-items-center justify-content-sm-center justify-content-xl-start mb-sm-4 mb-xl-0">
              {socialAccountInstagram.image_url ? (
                <div className="position-relative me-3" style={{ width: "40px" }}>
                  <Image src={socialAccountInstagram.image_url} roundedCircle width="40px" height="40px" className="object-fit-cover" />
                  <span className="position-absolute bottom-0 end-0 p-2 bg-image bg-image--instagram border border-light rounded-circle"></span>
                </div>
              ) : null}
              <div className="d-flex flex-column text-center">
                <span className="fw-bold">{socialAccountInstagram.account_name}</span>
                <span className="fw-light">
                  <small>{socialAccountInstagram.website}</small>
                </span>
              </div>
            </div>
          </Col>

          {withDetails ? (
            <>
              <Col sm={6} xl={3}>
                <div className="d-flex flex-column text-center">
                  <span className="fw-bold">{socialAccountInstagram.post_count}</span>
                  <span className="fw-light">
                    <small>{t("social.instagram.publications")}</small>
                  </span>
                </div>
              </Col>

              <Col sm={6} xl={3}>
                <div className="d-flex flex-column text-center">
                  <span className="fw-bold">{socialAccountInstagram.follower_count}</span>
                  <span className="fw-light">
                    <small>{t("social.instagram.followers")}</small>
                  </span>
                </div>
              </Col>
            </>
          ) : null}

          {withStatus ? (
            <Col>
              <div className="d-flex justify-content-end align-items-center">
                <span className="fw-bold me-1">
                  <small>{t("social.connected")}</small>
                </span>
                <Image src={checkIconImage} alt="" width="32px" />
              </div>
            </Col>
          ) : null}
        </Row>
      ) : null}
    </div>
  );
};

export default SocialAccount;
