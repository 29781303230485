import { createAsyncThunk } from "@reduxjs/toolkit";
import { format } from "date-fns";

import { getAuthToken } from "../../../services/auth";

export const fetchAutomations = createAsyncThunk(
  "automations/fetch",
  async ({ shopId, count, page }: { shopId: string; count?: number; page?: number }) => {
    const token = getAuthToken();

    const response = await fetch(
      new Request(
        process.env.REACT_APP_API_ENTRYPOINT +
          `/shops/${shopId}/social_automations?order[createdAt]=desc&itemsPerPage=${count || 10}&page=${page || 1}`,
        {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          }),
          cache: "no-cache",
        }
      )
    );

    if (!response.ok) {
      throw new Error("Erreur lors de la récupération des automations");
    }

    const data = await response.json();

    return data;
  }
);

export const createAutomation = createAsyncThunk("automations/create", async (automationData: any) => {
  const token = getAuthToken();

  const response = await fetch(
    new Request(process.env.REACT_APP_API_ENTRYPOINT + "/social_automations", {
      method: "POST",
      body: JSON.stringify(automationData),
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    })
  );

  if (!response.ok) {
    throw new Error("Erreur lors de la création de l'automation");
  }

  const data = await response.json();

  return data;
});

export const updateAutomation = createAsyncThunk("automations/update", async (automationData: any) => {
  const token = getAuthToken();

  const response = await fetch(
    new Request(process.env.REACT_APP_API_ENTRYPOINT + `/social_automations/${automationData.id}`, {
      method: "PUT",
      body: JSON.stringify(automationData),
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    })
  );

  if (!response.ok) {
    throw new Error("Erreur lors de la modification de l'automation");
  }

  const data = await response.json();

  return data;
});

export const deleteAutomation = createAsyncThunk("automations/delete", async (automationId: string) => {
  const token = getAuthToken();

  const response = await fetch(
    new Request(process.env.REACT_APP_API_ENTRYPOINT + `/social_automations/${automationId}`, {
      method: "DELETE",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    })
  );

  if (!response.ok) {
    throw new Error("Erreur lors de la suppression de l'automation");
  }

  return { automationId };
});

export const suspendAutomation = createAsyncThunk(
  "automations/suspend",
  async ({ automationId, isEnable }: { automationId: string; isEnable: boolean }) => {
    const token = getAuthToken();

    const response = await fetch(
      new Request(process.env.REACT_APP_API_ENTRYPOINT + `/social_automations/${automationId}`, {
        method: "PATCH",
        body: JSON.stringify({
          suspendedAt: isEnable ? format(new Date(), "yyyy-MM-dd") : null,
        }),
        headers: new Headers({
          "Content-Type": "application/vnd.api+json",
          Authorization: "Bearer " + token,
        }),
      })
    );

    if (!response.ok) {
      throw new Error("Erreur lors de l'activation/désactivation de l'automation");
    }

    const data = await response.json();

    return data;
  }
);

export const archiveAutomation = createAsyncThunk("automations/archive", async (automationId: string) => {
  const token = getAuthToken();

  const response = await fetch(
    new Request(process.env.REACT_APP_API_ENTRYPOINT + `/social_automations/${automationId}`, {
      method: "PATCH",
      body: JSON.stringify({
        deletedAt: format(new Date(), "yyyy-MM-dd"),
      }),
      headers: new Headers({
        "Content-Type": "application/vnd.api+json",
        Authorization: "Bearer " + token,
      }),
    })
  );

  if (!response.ok) {
    throw new Error("Erreur lors de l'archivage de l'automation");
  }

  const data = await response.json();

  return data;
});
