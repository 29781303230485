import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AutomationForm from "../../../organisms/Automation/AutomationForm";
import { Card, Col, Row } from "react-bootstrap";

interface AutomationAddProps {}

const AutomationAdd: React.FC<AutomationAddProps> = ({}: AutomationAddProps) => {
  const params = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("page.social_automations.create.meta_title");
  }, []);

  return (
    <>
      <h3>{t("page.social_automations.title")}</h3>
      <Row className="justify-content-center">
        <Col xl={8}>
          <Card className="rounded-4 bg-tagether-light-blue">
            <Card.Body>
              <Card.Title className="fw-bold">{t(`social_automation.type.${params.type}.${params.social}.${params.socialContentType}.title`)}</Card.Title>
              <Card.Text className="pt-2">{t(`social_automation.type.${params.type}.${params.social}.${params.socialContentType}.full_description`)}</Card.Text>
              <AutomationForm {...params} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AutomationAdd;
