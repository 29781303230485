import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

import { Plus as AddIcon } from "react-bootstrap-icons";
import iconRewardAction from "../../../assets/images/icons/icon-reward.svg";
import iconRetainAction from "../../../assets/images/icons/icon-loyalty.svg";
import iconAutomateAction from "../../../assets/images/icons/icon-story.svg";

const Actions: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleCardClick = (path: string): void => {
    navigate(path);
  };

  return (
    <>
      <Row className="">
        <Col lg={12} xl={6}>
          <Card
            className="d-flex flex-row justify-content-between align-items-center h-100 px-3 mb-3 bg-light btn-action"
            onClick={() => handleCardClick("/rewards")}
          >
            <Card.Img variant="left" src={iconRewardAction} width={40} className="flex-shrink-0" />
            <Card.Body className="w-100">
              <Card.Text className="fs-6 fw-bold">{t("actions.reward")}</Card.Text>
            </Card.Body>
            <AddIcon width={35} height={35} className="flex-shrink-0" />
          </Card>
        </Col>
        <Col lg={12} xl={6}>
          <Card
            className="d-flex flex-row justify-content-between align-items-center h-100 px-3 mb-3 bg-light btn-action"
            onClick={() => handleCardClick("/instagram/contents")}
          >
            <Card.Img variant="left" src={iconRetainAction} width={40} className="flex-shrink-0" />
            <Card.Body className="w-100">
              <Card.Text className="fs-6 fw-bold">{t("actions.retainCustomer")}</Card.Text>
            </Card.Body>
            <AddIcon width={35} height={35} className="flex-shrink-0" />
          </Card>
        </Col>
        {/* <Col lg={12} xl={4}>
          <Card
            className="d-flex flex-row justify-content-between align-items-center h-100 px-3 mb-3 bg-light btn-action"
            onClick={() => handleCardClick("/automations")}
          >
            <Card.Img variant="left" src={iconAutomateAction} width={40} className="flex-shrink-0" />
            <Card.Body className="w-100">
              <Card.Text className="fs-6 fw-bold">{t("actions.automateStoryMention")}</Card.Text>
            </Card.Body>
            <AddIcon width={35} height={35} className="flex-shrink-0" />
          </Card>
        </Col> */}
      </Row>
    </>
  );
};

export default Actions;
