import React from "react";
import { useTranslation } from "react-i18next";
import { Form, InputGroup } from "react-bootstrap";

interface FilterProps {
  className?: string;
  type: string;
  label: string;
  value: any;
  onChange: (value: any) => void;
  options: any;
}

const Filter: React.FC<FilterProps> = ({ className, type, label, value, onChange, options }: FilterProps) => {
  const { t } = useTranslation();

  return (
    <InputGroup size="sm" className={className}>
      <InputGroup.Text>{label}</InputGroup.Text>
      {type === "select" ? (
        <>
          <Form.Select aria-label={label} value={value ?? ""} onChange={(e) => onChange(e.target.value)}>
            <option value="">{t("filters.all")}</option>
            {options.map((opt: any) => (
              <option key={opt.value} value={opt.value}>
                {opt.label}
              </option>
            ))}
          </Form.Select>
        </>
      ) : type === "range" ? (
        <>
          <Form.Control
            type="number"
            min="0"
            placeholder={t("filters.min")}
            value={value?.gte ?? ""}
            onChange={(e) => onChange({ gte: e.target.value ? Number(e.target.value) : undefined })}
          />
          <Form.Control
            type="number"
            min="0"
            placeholder={t("filters.max")}
            value={value?.lte ?? ""}
            onChange={(e) => onChange({ lte: e.target.value ? Number(e.target.value) : undefined })}
          />
        </>
      ) : null}
    </InputGroup>
  );
};

export default Filter;
