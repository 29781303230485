import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../types/store";
import { fetchRewards } from "./reward-actions";

interface RewardsState {
  rewards: Array<any>; // @todo
  totalItems: number;
  loading: boolean;
  error: string | null;
}

const initialState: RewardsState = {
  rewards: [],
  totalItems: 0,
  loading: false,
  error: null,
};

const rewardsSlice = createSlice({
  name: "rewards",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRewards.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.totalItems = 0;
        state.rewards = [];
      })
      .addCase(fetchRewards.fulfilled, (state, action) => {
        state.loading = false;
        state.rewards = action.payload["hydra:member"];
        state.totalItems = action.payload["hydra:totalItems"];
      })
      .addCase(fetchRewards.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
        state.totalItems = 0;
      });
  },
});

export const {} = rewardsSlice.actions;

export const selectRewards = (state: RootState) => state.shopRewards.rewards;
export const selectTotalItems = (state: RootState) => state.shopRewards.totalItems;
export const selectLoading = (state: RootState) => state.shopRewards.loading;
export const selectError = (state: RootState) => state.shopRewards.error;

export default rewardsSlice.reducer;
