import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import Image from "react-bootstrap/Image";

import Table from "../../molecules/Table";
import Loader from "../../atoms/Loader";
import Button from "../../atoms/Button";

import instagramLogo from "../../../assets/images/instagram.svg";

import {
  selectSocialAuthors,
  selectTotalItems,
  selectLoading as selectSocialAuthorLoading,
  selectError as selectSocialAuthorError,
} from "../../../stores/Shop/Social/SocialAuthor/social-authors-slice";
import { fetchSocialAuthors } from "../../../stores/Shop/Social/SocialAuthor/social-author-actions";
import {
  selectSocialAccountInstagram,
  selectLoading as selectSocialAccountLoading,
} from "../../../stores/Shop/Social/SocialAccount/social-accounts-slice";
import { Socials } from "../../../enums/SocialContentTypes";
import SocialAuthorEmailForm from "./SocialAuthorEmailForm";

interface SocialAuthorTableProps {
  social: string;
  theme?: string;
  withPaginate?: boolean;
}

const SocialAuthorTable: FC<SocialAuthorTableProps> = ({ social, theme, withPaginate }: SocialAuthorTableProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const socialAccount = useSelector(selectSocialAccountInstagram);
  const socialAccountLoading = useSelector(selectSocialAccountLoading);

  const socialAuthors = useSelector(selectSocialAuthors);
  const socialAuthorLoading = useSelector(selectSocialAuthorLoading);
  const socialAuthorTotalItems = useSelector(selectTotalItems);
  const socialAuthorError = useSelector(selectSocialAuthorError);

  const isLoading = socialAccountLoading || socialAuthorLoading;

  const [page, setPage] = useState(1);

  const handleAuthorClick = (socialAuthor: any) => {
    navigate(`/${social}/authors/${socialAuthor.username}`);
  };

  const handlePaginate = (page: number) => {
    setPage(page);
  };

  useEffect(() => {
    if (socialAccount) {
      // @ts-ignore
      dispatch(fetchSocialAuthors({ socialAccountId: socialAccount.id, page: page }));
    }
  }, [page, socialAccount, dispatch]);

  if (socialAuthorError) {
    toast.error(t("errors.http"));
  }

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Loader />
      </div>
    );
  }

  const socialIcons: Record<Socials, string> = {
    instagram: instagramLogo,
  };

  const socialIcon = socialIcons[social as Socials] || null;

  const headers = [
    {
      content: t("social.ambassador.label"),
      className: "align-middle text-center rounded-top-start",
    },
    {
      content: t("social.content_count.label"),
      className: "align-middle text-center",
    },
    {
      content: t("social.feed_count.label"),
      className: "align-middle text-center",
    },
    {
      content: t("social.reel_count.label"),
      className: "align-middle text-center",
    },
    {
      content: t("social.like_avg.label"),
      className: "align-middle text-center",
    },
    {
      content: t("social.reward_count.label"),
      className: "align-middle text-center",
    },
    {
      content: t("social.channel.label"),
      className: "align-middle text-center",
    },
    {
      content: t("social.profile.label"),
      className: "align-middle text-center rounded-top-end",
    },
  ];

  const rows: Array<Array<any>> = socialAuthors.map((socialAuthor: any) => {
    const socialAuthorUsername: string = socialAuthor.username ? "@" + socialAuthor.username : "-";
    const socialContentCount: number = socialAuthor.count;
    const socialContentFeedCount: number = socialAuthor.feed_count;
    const socialContentReelCount: number = socialAuthor.reel_count;
    const socialContentLikeAvg: number = parseFloat(socialAuthor.like_avg);
    const socialRewardCount: number = socialAuthor.discount_count;

    return [
      {
        content: (
          <>
            <div className="mb-2">{socialAuthorUsername}</div>
            <SocialAuthorEmailForm socialAccount={socialAccount} author={socialAuthor} />
          </>
        ),
        className: "content-table align-middle",
      },
      {
        content: socialContentCount,
        className: "content-table align-middle text-center",
      },
      {
        content: socialContentFeedCount,
        className: "content-table align-middle text-center",
      },
      {
        content: socialContentReelCount,
        className: "content-table align-middle text-center",
      },
      {
        content: socialContentLikeAvg.toFixed(2),
        className: "content-table align-middle text-center",
      },
      {
        content: socialRewardCount,
        className: "content-table align-middle text-center",
      },
      {
        content: (
          <>{socialIcon ? <Image src={socialIcon} roundedCircle width="20px" height="20px" className="object-fit-cover" alt={social} /> : null}</>
        ),
        className: "content-table align-middle text-center",
      },
      {
        content: (
          <Button
            theme="dark"
            className="btn-sm fw-bold rounded-2 content-table-media align-middle text-center"
            label={t("actions.view")}
            onClick={() => handleAuthorClick(socialAuthor)}
          />
        ),
        className: "content-table align-middle text-center",
      },
    ];
  });

  let tableProps: any = {
    headers: headers,
    rows: rows,
    loading: isLoading,
    theme: theme,
  };

  if (withPaginate) {
    tableProps.paginate = { active: page, pages: Math.ceil(socialAuthorTotalItems / 10), onPaginate: handlePaginate };
  }

  return (
    <>
      <Table {...tableProps} />
    </>
  );
};

export default SocialAuthorTable;
