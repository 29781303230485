import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { Button, Form, InputGroup } from "react-bootstrap";

import { PencilFill as EditIcon } from "react-bootstrap-icons";
import { XLg as CancelIcon } from "react-bootstrap-icons";
import { Check as SaveIcon } from "react-bootstrap-icons";

import Submit from "../../atoms/Submit";

import { updateSocialAuthor } from "../../../stores/Shop/Social/SocialAuthor/social-author-actions";

interface SocialAuthorEmailFormProps {
  socialAccount: any;
  author: {
    username: string;
    email?: string;
  };
}

interface FormData {
  email?: string;
}

const SocialAuthorEmailForm: React.FC<SocialAuthorEmailFormProps> = ({ socialAccount, author }: SocialAuthorEmailFormProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    email: author.email || "",
  });
  const [formErrors, setFormErrors] = useState<Partial<FormData>>({});

  const handleEditClick = () => {
    setIsEditing(!isEditing);
    setFormData({ email: author.email || "" });
    setFormErrors({});
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, type, checked, value } = e.target;

    setFormData({ ...formData, [name]: type === "checkbox" ? checked : value });
    setFormErrors({ ...formErrors, [name]: "" });
  };

  const validateForm = () => {
    const errors: Partial<FormData> = {};

    if (!formData.email || (formData.email && !emailRegex.test(formData.email))) {
      errors.email = t("form.social_author_email_form.email.error");
    }

    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);

    const data = {
      socialAccountId: socialAccount.id,
      username: author.username,
      email: formData.email,
    };

    try {
      // @ts-ignore
      await dispatch(updateSocialAuthor(data)).unwrap();
      setIsEditing(false);
      toast.success(t("form.social_author_email_form.success"));
    } catch (error) {
      toast.error(t("form.social_author_email_form.error"));
    }

    setIsSubmitting(false);
  };

  return (
    <>
      <Form method="post" noValidate onSubmit={handleSubmit}>
        <InputGroup size="sm" hasValidation>
          {isEditing ? (
            <>
              <Form.Control type="email" name="email" value={formData.email} onChange={handleChange} isInvalid={!!formErrors.email} />
              <Button variant="outline-danger" onClick={handleEditClick}>
                <CancelIcon />
              </Button>
              <Submit theme="outline-success" label={<SaveIcon />} loading={isSubmitting} />
              <Form.Control.Feedback type="invalid">{formErrors.email}</Form.Control.Feedback>
            </>
          ) : (
            <>
              <Form.Control type="email" name="email" value={formData.email} placeholder="Email" readOnly />
              <Button variant="outline-secondary" onClick={handleEditClick}>
                <EditIcon />
              </Button>
            </>
          )}
        </InputGroup>
      </Form>
    </>
  );
};

export default SocialAuthorEmailForm;
