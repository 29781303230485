import React, { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { format } from "date-fns";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Badge from "react-bootstrap/Badge";

import Submit from "../../atoms/Submit";
import Button from "../../atoms/Button";

import { RewardSocialContentTypeLabels, RewardTypeThemes, getRewardTypeTheme } from "../../../enums/RewardTypes";
import { createConfigReward, updateConfigReward } from "../../../stores/Shop/ConfigReward/config-reward-actions";
import { selectShop } from "../../../stores/Shop/shop-slice";
import { selectUser } from "../../../stores/User/owner-slice";

interface ConfigRewardFormProps {
  type?: string;
  configReward?: any;
}

interface FormData {
  socialContentTypes: Array<string>;
  place: string;
  code: string;
  percent: string;
  description: string;
  validityStartAt: string;
  validityEndAt: string;
  quantity: string;
}

const ConfigRewardForm: FC<ConfigRewardFormProps> = ({ type, configReward }: ConfigRewardFormProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const owner = useSelector(selectUser);
  const shop = useSelector(selectShop);

  const [formData, setFormData] = useState({
    socialContentTypes: configReward?.socialContentTypes || [],
    place: configReward?.place || "online",
    code: configReward?.code || "",
    percent: configReward?.percent || 0,
    description: configReward?.description || "",
    validityStartAt: configReward?.validity_start_at ? format(new Date(configReward.validity_start_at), "yyyy-MM-dd") : "",
    validityEndAt: configReward?.validity_end_at ? format(new Date(configReward.validity_end_at), "yyyy-MM-dd") : "",
    quantity: configReward?.quantity || "",
  });

  const [formErrors, setFormErrors] = useState<Partial<FormData>>({});

  const typeLabel: string = t(`reward.type.${type}`);
  const typeTheme: string = getRewardTypeTheme(type as keyof typeof RewardTypeThemes);

  const isRewardCode = type === "code";
  const isRewardPercent = type === "percent";

  const rewardSocialContentTypeKeys = Object.keys(RewardSocialContentTypeLabels);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: "" });
  };

  const handleSelectChange = (e: React.ChangeEvent<any>) => {
    const { name, options } = e.target;
    const selectedValues = Array.from(options as HTMLCollectionOf<HTMLOptionElement>)
      .filter((option) => option.selected)
      .map((option) => option.value);

    setFormData({ ...formData, [name]: selectedValues });
    setFormErrors({ ...formErrors, [name]: "" });
  };

  const validateForm = () => {
    const errors: Partial<FormData> = {};

    if (isRewardCode) {
      if (!formData.code) {
        errors.code = t("reward.fields.code.error");
      }
    }

    if (isRewardPercent) {
      if (!formData.percent) {
        errors.percent = t("reward.fields.percent.error");
      }
    } else {
      if (!formData.description) {
        errors.description = t("reward.fields.description.error");
      }
    }

    if (formData.validityStartAt || formData.validityEndAt) {
      if (!formData.validityStartAt || !formData.validityEndAt) {
        errors.validityStartAt = t("reward.fields.validityStartAt.error.blank");
        errors.validityEndAt = t("reward.fields.validityEndAt.error.blank");
      } else {
        const startDate = new Date(formData.validityStartAt);
        const endDate = new Date(formData.validityEndAt);

        if (startDate > endDate) {
          errors.validityStartAt = t("reward.fields.validityStartAt.error.interval");
          errors.validityEndAt = t("reward.fields.validityEndAt.error.interval");
        }
      }
    }

    if (formData.quantity !== null && formData.quantity < 0) {
      errors.quantity = t("reward.fields.quantity.error");
    }

    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const data = {
      id: configReward ? configReward.id : null,
      type: type,
      socialContentTypes: formData.socialContentTypes,
      place: formData.place ? formData.place : "online",
      code: formData.code ? formData.code : null,
      percent: formData.percent ? parseInt(formData.percent) : null,
      description: formData.description ? formData.description : null,
      validityStartAt: formData.validityStartAt ? formData.validityStartAt : null,
      validityEndAt: formData.validityEndAt ? formData.validityEndAt : null,
      quantity: formData.quantity ? parseInt(formData.quantity) : null,
      shop: shop["@id"],
      createdBy: owner["@id"],
    };

    try {
      if (configReward) {
        // @ts-ignore
        await dispatch(updateConfigReward(data)).unwrap();
        toast.success(t("config_reward.success.update"));
      } else {
        // @ts-ignore
        await dispatch(createConfigReward(data)).unwrap();
        toast.success(t("config_reward.success.create"));
      }

      navigate("/rewards");
    } catch (error) {
      toast.error(t("form.config_reward_form.error"));
    }
  };

  return (
    <Form method="post" noValidate onSubmit={handleSubmit} className="m-5 add-form-reward">
      <Row className="justify-content-center">
        <Col lg={8}>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={4} className="fw-bold">
              {t("reward.type.label")}
            </Form.Label>
            <Col sm={8}>
              <Badge bg={typeTheme}>{typeLabel}</Badge>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={4} className="fw-bold">
              {t("reward.social_content_type.label")}
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                as="select"
                name="socialContentTypes"
                required
                defaultValue={formData.socialContentTypes}
                onChange={handleSelectChange}
                isInvalid={!!formErrors.socialContentTypes}
                multiple={true}
              >
                {rewardSocialContentTypeKeys.map((key) => (
                  <option key={key} value={key}>
                    {t(`reward.social_content_type.${key}`)}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">{t("form.config_reward_form.social_content_type.error")}</Form.Control.Feedback>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={4} className="fw-bold">
              {t("reward.place.label")}
            </Form.Label>
            <Col sm={4}>
              <Form.Check
                type="radio"
                label={t("reward.place.online")}
                value="online"
                id="configRewardPlaceOnline"
                name="place"
                checked={formData.place === "online"}
                onChange={handleChange}
              />
            </Col>
            <Col sm={4}>
              <Form.Check
                type="radio"
                label={t("reward.place.shop")}
                value="shop"
                id="configRewardPlaceShop"
                name="place"
                checked={formData.place === "shop"}
                onChange={handleChange}
              />
            </Col>
          </Form.Group>

          {isRewardCode ? (
            <Form.Group as={Row} className="mb-3" controlId="configRewardCode">
              <Form.Label column sm={4} className="fw-bold">
                {t("reward.code.label")}
              </Form.Label>
              <Col sm={8}>
                <Form.Control type="text" name="code" required value={formData.code} onChange={handleChange} isInvalid={!!formErrors.code} />
                <Form.Control.Feedback type="invalid">{formErrors.code}</Form.Control.Feedback>
              </Col>
            </Form.Group>
          ) : null}

          {isRewardPercent ? (
            <Form.Group as={Row} className="mb-3" controlId="configRewardPercent">
              <Form.Label column sm={4} className="fw-bold">
                {t("reward.percent.label")}
              </Form.Label>
              <Col sm={8}>
                <InputGroup>
                  <Form.Control
                    type="number"
                    name="percent"
                    required
                    value={formData.percent}
                    onChange={handleChange}
                    isInvalid={!!formErrors.percent}
                  />
                  <InputGroup.Text>%</InputGroup.Text>
                </InputGroup>
                <Form.Control.Feedback type="invalid">{formErrors.percent}</Form.Control.Feedback>
              </Col>
            </Form.Group>
          ) : (
            <Form.Group as={Row} className="mb-3" controlId="configRewardDescription">
              <Form.Label column sm={4} className="fw-bold">
                {t("reward.value.label")}
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  type="text"
                  name="description"
                  required
                  value={formData.description}
                  onChange={handleChange}
                  isInvalid={!!formErrors.description}
                />
                <Form.Control.Feedback type="invalid">{formErrors.description}</Form.Control.Feedback>
              </Col>
            </Form.Group>
          )}

          <Form.Group as={Row} className="mb-3" controlId="configRewardValidity">
            <Form.Label column sm={4} className="fw-bold">
              {t("reward.validity.label")}
            </Form.Label>
            <Col sm={4}>
              {t("reward.validity.from")}{" "}
              <Form.Control
                type="date"
                name="validityStartAt"
                value={formData.validityStartAt}
                onChange={handleChange}
                isInvalid={!!formErrors.validityStartAt}
              />
              <Form.Control.Feedback type="invalid">{formErrors.validityStartAt}</Form.Control.Feedback>
            </Col>
            <Col sm={4}>
              {t("reward.validity.to")}{" "}
              <Form.Control
                type="date"
                name="validityEndAt"
                value={formData.validityEndAt}
                onChange={handleChange}
                isInvalid={!!formErrors.validityEndAt}
              />
              <Form.Control.Feedback type="invalid">{formErrors.validityEndAt}</Form.Control.Feedback>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="configRewardQuantity">
            <Form.Label column sm={4} className="fw-bold">
              {t("reward.quantity.label")}
            </Form.Label>
            <Col sm={8}>
              <Form.Control type="number" name="quantity" value={formData.quantity} onChange={handleChange} isInvalid={!!formErrors.quantity} />
              <Form.Control.Feedback type="invalid">{formErrors.quantity}</Form.Control.Feedback>
            </Col>
          </Form.Group>

          <div className="text-end">
            <Button theme="outline-tagether" to="/rewards" label={t("actions.back")} className="me-2" />
            <Submit theme="tagether" label={t("actions.submit.save")} />
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default ConfigRewardForm;
