import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../../types/store";
import { fetchSocialAuthors } from "./social-author-actions";

interface SocialAuthorState {
  socialAuthors: Array<any>; // @todo
  totalItems: number;
  loading: boolean;
  error: string | null;
}

const initialState: SocialAuthorState = {
  socialAuthors: [],
  totalItems: 0,
  loading: false,
  error: null,
};

const socialAuthorsSlice = createSlice({
  name: "socialAuthors",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSocialAuthors.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.totalItems = 0;
        state.socialAuthors = [];
      })
      .addCase(fetchSocialAuthors.fulfilled, (state, action) => {
        const data = action.payload["hydra:member"];
        const items = data[0];
        const totalItems = data[1];

        state.loading = false;
        state.socialAuthors = items;
        state.totalItems = totalItems;
      })
      .addCase(fetchSocialAuthors.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
        state.totalItems = 0;
      });
  },
});

export const {} = socialAuthorsSlice.actions;

export const selectSocialAuthors = (state: RootState) => state.shopSocialAuthors.socialAuthors;
export const selectTotalItems = (state: RootState) => state.shopSocialAuthors.totalItems;
export const selectLoading = (state: RootState) => state.shopSocialAuthors.loading;
export const selectError = (state: RootState) => state.shopSocialAuthors.error;

export default socialAuthorsSlice.reducer;
