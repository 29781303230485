import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import SocialAuthorTable from "../../../organisms/Social/SocialAuthorTable";
import { useParams } from "react-router-dom";

const SocialAuthors = () => {
  const { t } = useTranslation();
  const params = useParams();

  const social = params.social || "";

  useEffect(() => {
    document.title = t("page.social_authors.meta_title");
  }, []);

  return (
    <div className="py-3">
      <h3 className="fw-bold mb-2">{t("page.social_authors.title")}</h3>
      <h6 className="fw-light mb-4">{t("page.social_authors.subtitle")}</h6>
      <SocialAuthorTable social={social} theme="light" withPaginate />
    </div>
  );
};

export default SocialAuthors;
