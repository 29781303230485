import React from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

import { RewardTypeThemes, RewardTypes, getRewardTypeTheme } from "../../../enums/RewardTypes";

interface ConfigRewardCardProps {
  configReward: any;
  isSelected: boolean;
  onToggleSelect?: any;
}

const ConfigRewardCard: React.FC<ConfigRewardCardProps> = ({ configReward, isSelected, onToggleSelect }: ConfigRewardCardProps) => {
  const { t } = useTranslation();

  const type: string = configReward.type;
  const typeTheme: string = getRewardTypeTheme(type as keyof typeof RewardTypeThemes);
  const typeLabel: string = t(`reward.type.${type}`);
  const value: string = configReward.description ?? configReward.percent + " %";
  const code: string = configReward.code;
  const validityStartAt: string | null = configReward.validity_start_at ? format(new Date(configReward.validity_start_at), "dd/MM/yyyy") : null;
  const validityEndAt: string | null = configReward.validity_end_at ? format(new Date(configReward.validity_end_at), "dd/MM/yyyy") : null;
  const validity: string | null = validityStartAt && validityEndAt ? validityStartAt + " - " + validityEndAt : null;
  const quantityRemaining: number | null = configReward.quantityRemaining ?? null;

  const isRewardCode = type === RewardTypes.code;

  const handleCardClick = () => {
    if (onToggleSelect) {
      onToggleSelect(configReward);
    }
  };

  return (
    <Card
      className={`mb-1 border-2 rounded-2 ${isSelected ? "border-" + typeTheme : ""}`}
      style={{ cursor: onToggleSelect ? "pointer" : "" }}
      onClick={handleCardClick}
    >
      <Row className="g-0 justify-content-center align-item-center p-2">
        <Col xs={2} className={`reward-icon reward-icon-${type}`}></Col>
        <Col xs={10}>
          <Card.Body className="config-reward-card-content">
            <Card.Text className="ta-center mb-0">{typeLabel}</Card.Text>
            <Card.Text className="ta-center mb-0">
              {isRewardCode ? (
                <>
                  <span>{code}</span>-
                </>
              ) : null}
              <span>{value.slice(0, 15)}</span>
            </Card.Text>
            {validity ? (
              <Card.Text className="ta-center mb-0">
                <small>
                  <span>{validity}</span>
                </small>
              </Card.Text>
            ) : null}
            {quantityRemaining ? (
              <Card.Text>
                <small>
                  {t("reward.quantity_remaining.label")} : <span className="fw-bold">{quantityRemaining}</span>
                </small>
              </Card.Text>
            ) : null}
          </Card.Body>
        </Col>
      </Row>
    </Card>
  );
};

export default ConfigRewardCard;
