import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import RewardTable from "../../../organisms/Reward/RewardTable";
import { useParams } from "react-router-dom";

const SocialAuthorRewards = () => {
  const { t } = useTranslation();
  const params = useParams();
  const username = params.username;
  const rewardOptions = {
    "socialContent.authorUsername": username,
  };

  useEffect(() => {
    document.title = t("page.social_authors.meta_title");
  }, []);

  return (
    <>
      <RewardTable options={rewardOptions} theme="dark-blue" withPaginate />
    </>
  );
};

export default SocialAuthorRewards;
