import React from "react";
import SocialAuthorAccount from "./SocialAuthorAccount";

interface SocialAuthorHeaderProps {
  social: string;
  username: string;
}

const SocialAuthorHeader: React.FC<SocialAuthorHeaderProps> = ({ social, username }: SocialAuthorHeaderProps) => {
  return (
    <div className="d-flex justify-content-between align-items-center border-bottom">
      <SocialAuthorAccount social={social} username={username} className="py-3 border-bottom border-tagether-blue border-3" />
    </div>
  );
};

export default SocialAuthorHeader;
