import { createAsyncThunk } from "@reduxjs/toolkit";

import { getAuthToken } from "../../../../services/auth";

const buildUrl = (baseUrl: string, params: Record<string, any>): string => {
  const searchParams = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    if (value !== undefined && value !== null && value !== "") {
      searchParams.append(key, value.toString());
    }
  });

  return `${baseUrl}?${searchParams.toString()}`;
};

export const fetchSocialContentInstagrams = createAsyncThunk(
  "socialContents/fetchSocialContentInstagrams",
  async ({
    socialAccountId,
    count,
    page,
    options,
    type,
    like_count,
  }: {
    socialAccountId: string;
    count?: number;
    page?: number;
    options?: { [key: string]: any };
    type?: string;
    like_count?: { lte?: number; gte?: number };
  }) => {
    const url = buildUrl("/social_content_instagrams", {
      socialAccount: socialAccountId,
      "order[createdAt]": "desc",
      itemsPerPage: count || 12,
      page: page || 1,
      ...options,
      type: type || "",
      "likeCount[lte]": like_count?.lte,
      "likeCount[gte]": like_count?.gte,
    });

    const token = getAuthToken();
    const response = await fetch(
      new Request(process.env.REACT_APP_API_ENTRYPOINT + url, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }),
        cache: "no-cache",
      })
    );

    if (!response.ok) {
      throw new Error("Erreur lors de la récupération des contenus Instagram");
    }

    const data = await response.json();

    return data;
  }
);

export const fetchSocialContentInstagram = createAsyncThunk(
  "socialContents/fetchSocialContentInstagram",
  async ({ socialContentId }: { socialContentId: string }) => {
    const token = getAuthToken();
    const response = await fetch(
      new Request(process.env.REACT_APP_API_ENTRYPOINT + `/social_content_instagrams/${socialContentId}`, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }),
        cache: "no-cache",
      })
    );

    if (!response.ok) {
      throw new Error("Erreur lors de la récupération du contenu Instagram");
    }

    const data = await response.json();

    return data;
  }
);
