import React from "react";
import { useSelector } from "react-redux";

import Image from "react-bootstrap/Image";

import SocialAccount from "./SocialAccount";

import tokenIconImage from "../../../assets/images/icons/icon-token.svg";

import { selectShop } from "../../../stores/Shop/shop-slice";

interface SocialHeaderProps {
  social: string;
}

const SocialHeader: React.FC<SocialHeaderProps> = ({ social }: SocialHeaderProps) => {
  const shop = useSelector(selectShop);

  return (
    <div className="d-flex justify-content-between align-items-center border-bottom">
      <SocialAccount social={social} withDetails className="py-3 border-bottom border-tagether-blue border-3" />
      {shop && shop.quota && (
        <div className="d-flex align-items-center bg-tagether-brown rounded-pill p-1">
          <Image src={tokenIconImage} alt="Récompenses à attribuer restantes" width="25px" />
          <div className="rounded-pill px-3 ms-2 bg-white text-tagether-yellow fw-bold">{shop.quotaRemaining}</div>
        </div>
      )}
    </div>
  );
};

export default SocialHeader;
