import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Card from "react-bootstrap/Card";
import Button from "../../atoms/Button";

interface ConfigRewardButtonCardProps {
  type: string;
}

const ConfigRewardButtonCard: React.FC<ConfigRewardButtonCardProps> = ({ type }: ConfigRewardButtonCardProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate(`/rewards/${type}/add`);
  };

  return (
    <Card
      className={`h-100 text-center border-1 rounded-4 pt-5 reward-icon reward-icon--large reward-icon-${type}`}
      style={{ cursor: "pointer" }}
      onClick={handleCardClick}
    >
      <Card.Body className="mt-4">
        <Card.Title className="fw-bold pt-5 mt-5">{t(`reward.type.${type}`)}</Card.Title>
        <Card.Text className="pt-1">{t(`reward.description.${type}`)}</Card.Text>
      </Card.Body>
      <Card.Footer className="bg-white border-0 mb-2">
        <Button className="rounded-2 fw-bold w-50" theme="primary" label={"Créer"} to={`/rewards/${type}/add`}/>
      </Card.Footer>
    </Card>
  );
};

export default ConfigRewardButtonCard;
