import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import SocialContentList from "../../../organisms/Social/SocialContentList";

const SocialContents = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("page.social_contents.meta_title");
  }, []);

  return (
    <>
      <SocialContentList />
    </>
  );
};

export default SocialContents;
