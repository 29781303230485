import { Link } from "react-router-dom";

import Image from "react-bootstrap/Image";

import logoImage from "../../assets/images/layout/logo.png";

interface LogoProps {
  withLink?: boolean;
}

const Logo: React.FC<LogoProps> = ({ withLink }: LogoProps) => {
  return withLink ? (
    <Link to="/">
      <Image src={logoImage} fluid alt="Logo Tagether" width="120px" />
    </Link>
  ) : (
    <Image src={logoImage} fluid alt="Logo Tagether" width="120px" />
  );
};

export default Logo;
