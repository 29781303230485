import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";

import ConfigRewardListCard from "../organisms/Reward/ConfigRewardListCard";
import RewardTable from "../organisms/Reward/RewardTable";
import SocialCard from "../organisms/Social/SocialCard";
import { selectShop } from "../../stores/Shop/shop-slice";
import { selectUser } from "../../stores/User/owner-slice";
import Actions from "../organisms/Layout/Actions";

const Home = () => {
  const { t } = useTranslation();

  const owner = useSelector(selectUser);
  const shop = useSelector(selectShop);

  useEffect(() => {
    document.title = t("page.home.meta_title");
  }, []);

  return (
    <>
      <Row className="gx-5 gy-4 mb-4">
        <Col lg={12} className="mb-3 mt-4">
          {shop ? <h1 className="fw-bold">Hey, {shop.name} 👋 </h1> : null}
        </Col>
        <Col lg={12}>
          <Actions />
        </Col>
        <Col lg={5} className="mb-3 mt-5 pt-2">
          <SocialCard header="Vos contenus clients" />
        </Col>
        <Col lg={7} className="mb-3 mt-5 pt-2">
          <ConfigRewardListCard />
        </Col>
      </Row>
      <Row className="mb-5 mt-5">
        <Col>
          <h3 className="fw-bold mb-2">{t("page.rewards.title")}</h3>
          {shop ? (
            <div className="card-count-container">
              <div className="bg-light-subtle card-count-box rounded-2">
                <div className="card-count-content">
                  <p className="card-count-container">
                    {t("reward.count.title")} {t("reward.count.total")}
                  </p>
                  <span>
                    <p className="font-blue-color">{shop.discountCount == 0 ? "-" : shop.discountCount}</p>
                  </span>
                </div>
                <div className="card-count-content">
                  <p className="card-count-container">
                    {t("reward.count.title")} {t("reward.count.month")}
                  </p>
                  <span>
                    <p className="font-blue-color">{shop.discountMonthCount == 0 ? "-" : shop.discountMonthCount}</p>
                  </span>
                </div>
              </div>
              {/* <div className="bg-light-subtle card-count-box card-count-box-space rounded-2">
              <span>
                <p className="font-blue-color">{shop.discountMonthCount == 0 ? "-" : shop.discountMonthCount}</p>
              </span>
              <p className="card-count-container">{t("reward.count.title")} {t("reward.count.month")}</p>
            </div> */}
            </div>
          ) : null}
          <Card border="rounded-2">
            <Card.Body>
              <RewardTable />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mb-5 mt-5">
        <Col>
          <h4 className="fw-bold mb-2">{t("page.faq.title")}</h4>
          <Accordion>
            <Accordion.Item eventKey="1">
              <Accordion.Header>{t("faq.item1.header")}</Accordion.Header>
              <Accordion.Body dangerouslySetInnerHTML={{ __html: t("faq.item1.body") }}></Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>{t("faq.item2.header")}</Accordion.Header>
              <Accordion.Body dangerouslySetInnerHTML={{ __html: t("faq.item2.body") }}></Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>{t("faq.item3.header")}</Accordion.Header>
              <Accordion.Body dangerouslySetInnerHTML={{ __html: t("faq.item3.body") }}></Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>{t("faq.item4.header")}</Accordion.Header>
              <Accordion.Body dangerouslySetInnerHTML={{ __html: t("faq.item4.body") }}></Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>{t("faq.item5.header")}</Accordion.Header>
              <Accordion.Body dangerouslySetInnerHTML={{ __html: t("faq.item5.body") }}></Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header>{t("faq.item6.header")}</Accordion.Header>
              <Accordion.Body dangerouslySetInnerHTML={{ __html: t("faq.item6.body") }}></Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header>{t("faq.item7.header")}</Accordion.Header>
              <Accordion.Body dangerouslySetInnerHTML={{ __html: t("faq.item7.body") }}></Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8">
              <Accordion.Header>{t("faq.item8.header")}</Accordion.Header>
              <Accordion.Body dangerouslySetInnerHTML={{ __html: t("faq.item8.body") }}></Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="9">
              <Accordion.Header>{t("faq.item9.header")}</Accordion.Header>
              <Accordion.Body dangerouslySetInnerHTML={{ __html: t("faq.item9.body") }}></Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
    </>
  );
};

export default Home;
