import React from "react";
import { Outlet, useParams } from "react-router-dom";
import SocialAuthorNavigation from "../organisms/Layout/SocialAuthorNavigation";
import SocialAuthorHeader from "../organisms/Social/SocialAuthorHeader";

const SocialAuthorLayout = () => {
  const params = useParams();
  const social = params.social || "";
  const username = params.username || "";

  return (
    <>
      <SocialAuthorHeader social={social} username={username} />
      <SocialAuthorNavigation social={social} username={username} />
      <div className="py-3">
        <Outlet />
      </div>
    </>
  );
};

export default SocialAuthorLayout;
