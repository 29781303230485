import React from "react";
import { useParams, Outlet } from "react-router-dom";

import SocialHeader from "../organisms/Social/SocialHeader";

const SocialLayout = () => {
  const params = useParams();
  const social = params.social || "";

  return (
    <>
      <SocialHeader social={social} />
      <div className="py-3">
        <Outlet />
      </div>
    </>
  );
};

export default SocialLayout;
