import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Col, Row } from "react-bootstrap";
import ConfigRewardTable from "../../../organisms/Reward/ConfigRewardTable";
import ConfigRewardButtonCard from "../../../organisms/Reward/ConfigRewardButtonCard";

import { selectShop } from "../../../../stores/Shop/shop-slice";
import { RewardTypes } from "../../../../enums/RewardTypes";

const ConfigRewards = () => {
  const { t } = useTranslation();
  const shop = useSelector(selectShop);

  useEffect(() => {
    document.title = t("page.config_rewards.meta_title");
  }, []);

  return (
    <div className="py-3">
      <h3 className="fw-bold mb-2">{t("page.config_rewards.subtitle_short")}</h3>
      <Row className="gy-4 mb-5 mt-3">
        {shop && shop.discountTypes.length ? (
          <>
            {shop.discountTypes.includes(RewardTypes.code) && (
              <Col xl={3} md={6}>
                <ConfigRewardButtonCard type={RewardTypes.code} />
              </Col>
            )}
            {shop.discountTypes.includes(RewardTypes.voucher) && (
              <Col xl={3} md={6}>
                <ConfigRewardButtonCard type={RewardTypes.voucher} />
              </Col>
            )}
            {shop.discountTypes.includes(RewardTypes.percent) && (
              <Col xl={3} md={6}>
                <ConfigRewardButtonCard type={RewardTypes.percent} />
              </Col>
            )}
            {shop.discountTypes.includes(RewardTypes.other) && (
              <Col xl={3} md={6}>
                <ConfigRewardButtonCard type={RewardTypes.other} />
              </Col>
            )}
          </>
        ) : null}
      </Row>
      <h3 className="fw-bold mb-5">{t("page.config_rewards.title")}</h3>
      <ConfigRewardTable theme="dark-blue" withPaginate />
    </div>
  );
};

export default ConfigRewards;
